body {
  margin: 0;
  // background-color: #444444 !important;
  background-color: #353533 !important;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: "Open Sans" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form_container {
  width: 30%;
}

.flex {
  display: flex;
}

.relative {
  position: relative;
}
.text-weight-bold {
  font-weight: 600;
}
.absolute {
  position: absolute;
}

.display-inline-flex {
  display: inline-flex;
}

html {
  scroll-behavior: smooth;
}
.amc-text-no-wrap {
  text-wrap: nowrap;
}

@media screen and (max-width: 1000px) {
  .form_container {
    width: 45%;
  }
}

@media screen and (max-width: 700px) {
  html,
  body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
  }
  .form_container {
    width: 65%;
  }
}
