@import "../../../../assets/path.scss";

.bg-grey {
  background-color: $light-grey !important;
}

header {
  overflow: hidden;
}

.amc-nav-logo {
  margin-right: 5rem;

  img {
    width: 15vw;
  }
}

.text-black {
  color: $black;
}

.mlr-4 {
  margin: 0 5vw;
}

.ml-10 {
  margin-left: 10rem;
}

.navBar-container {
  padding: 0.938rem 0 !important;
}

.register-btn {
  background-image: linear-gradient(to bottom right, $primary 0%, $primaryActive 91%);
  padding: 0.75rem 1.563rem;
  // letter-spacing: 0.047rem;
  border-radius: 1.563rem;
  font-weight: 600;
  border: none;
  font-size: 1.125rem;
  text-wrap: nowrap;
  color: #ffffffd9;
  &:hover {
    color: #ffffff;
  }
}

.nav-bar-container {
  display: flex;
  width: 50vw;
  justify-content: space-around;
}

.nav-link {
  font-weight: 600;
  font-size: 1.125rem !important;
  color: #353533;
  &:hover {
    // background-color: #D0D0CE;
    // color: #353533;
    // border-radius: 0.938rem;
    color: $black;
  }
}

.nav-link:focus,
.navbar-brand:focus {
  color: $black;
}
