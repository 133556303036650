.faq-modal-header {
  border: none;
  background-color: #f2f2f2;
}

.scroll-y {
  overflow-y: scroll;
}

.amc-offcanvas-container {
  .offcanvas-body {
    min-height: 100vh;
  }
}

