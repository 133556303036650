@import "../../../assets/path.scss";

.review-container {
  padding: 0 0 6rem 0;

  .title-header {
    padding: 1.5rem 2rem 1.5rem 2rem;
    background-color: #353533;
  }

  .review-loader-container {
    box-sizing: border-box;
    background: #ffffff;
    display: flex;
    align-items: center;
    height: 100vh;
    justify-content: center;
  }

  .table-head {
    border-width: 0 0 5px 0;
    border-style: solid;
    border-color: #43b02a !important;
    th {
      background-color: #000000 !important;
      color: #43b02a !important;
      border: none;
      padding: 1rem;
      font-size: 1.125rem;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      // position: sticky;
      // top: 0;
    }
  }

  .table-body {
    td {
      padding-top: 1.188rem;
      padding-bottom: 1.188rem;
      border: none;
      box-shadow: 8px 0 7.5px 0 rgba(0, 0, 0, 0.3);
    }

    td:first-child {
      color: #000000;
      padding-left: 1rem;
      text-wrap: nowrap;
    }
    tr:last-child {
      td {
        color: #000000;
        padding-left: 1rem;
        background-color: #d7f4d0;
      }
    }
  }

  .mlr-3 {
    margin: 0 3rem 0 0;
  }
}

.td-approved {
  display: flex;
  gap: 18px;
  align-items: center;
}

.td-initiated {
  display: flex;
  gap: 26px;
  align-items: center;
}

.td-rejected {
  display: flex;
  gap: 26px;
  align-items: center;
}

.status-td {
  vertical-align: middle;
  font-weight: 500;
}

.approved-icon {
  cursor: pointer;
  border-radius: 3px;
  width: 26px;
}

.approved-icon:hover {
  box-shadow: 0px 2px 5px green;
}

.initiated-icon {
  cursor: pointer;
  border-radius: 3px;
  width: 26px;
}

.initiated-icon:hover {
  box-shadow: 0px 2px 5px orange;
}

.rejected-icon {
  cursor: pointer;
  border-radius: 3px;
  width: 26px;
}

.rejected-icon:hover {
  box-shadow: 0px 2px 5px red;
}

.rejectedRecord {
  text-align: center;
  background-color: aliceblue !important;
  box-shadow: #95a5a6;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: large;
}

.rejected {
  font-weight: bolder;
}

/* Loader component */
.loading {
  opacity: 1;
  z-index: 1;
}

.loaderContainer {
  margin: auto;
  width: 50%;
  text-align: center;
  margin-top: 5%;
}

.loaderContainer div {
  justify-content: center;
}

.toCenterAlignLoading {
  width: fit-content;
  position: absolute;
  top: 40%;
  left: 40%;
  font-size: xx-large;
  border: 2px solid #ececec;
  padding: 2.5%;
  border-radius: 25px;
  -webkit-box-shadow: 10px 10px 11px 0px rgba(158, 158, 158, 1);
  -moz-box-shadow: 10px 10px 11px 0px rgba(158, 158, 158, 1);
  box-shadow: 10px 10px 11px 0px rgba(158, 158, 158, 1);
}

/* validated / readonly styles */
.readonly-fields {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%23198754%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.user-notified td {
  background-color: #d0f0c7 !important;
}

.amc-modal-body {
  padding: 2rem 2rem 1rem 2rem;
  font-size: 1.125rem;
}

.modal-form-container {
  padding: 0 2rem;
}
