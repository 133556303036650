.faq-container {
  padding: 2rem 2rem 2rem 2rem;
  background-color: #f2f2f2;
  color: #000000;

  .faq-footer {
    padding: 1.5rem;
    background-image: linear-gradient(to bottom right, #43b02a 0%, #00abab 100%);
    border-radius: 10px;
    margin-top: 1rem;
  }

  .tr-0 {
    top: 0;
    right: 0;
  }
}

.accordion-button {
  font-weight: 500;
  font-size: 21px;
  padding: 1rem;
}
