@import '../../../assets/path.scss';

.f-container {
  // min-height: calc(var(--vh, 1vh) * 100);
  background-color: $black;
  color: $white;
  padding: 4.688rem 10rem 7rem 1rem;

  .pl-4 {
    padding-left: 3.125rem;
  }

  .footer-desc {
    padding: 4.688rem 6rem 0 3.125rem;
  }

  .sub-container {
    display: flex;
    flex-direction: column;
    .btn-link {
      text-align: left;
    }
  }

  .text-grey {
    .btn-link {
      color: #bbbcbc;
    }
  }
}
