@import "../../../../assets/path.scss";

.back-top-btn {
  .btn {
    &:hover {
      background-image: url("../../../../../public/images/scroll-top.svg");
      opacity: 1;
      background-color: transparent !important;
    }
  }
}

.back-to-top {
  border-radius: 30px;
  font-weight: 600;
  text-wrap: nowrap;
  position: fixed;
  bottom: 25px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-image: url("../../../../../public/images/scroll-top.svg");
  background-repeat: no-repeat;
  background-size: contain;
  color: #ffffff;
  opacity: 0.6;
  padding: 15px;
  .up-arrow {
    text-align: center;
    line-height: 30px;
    width: 30px;
    height: 30px;
  }

  &:hover {
    .up-arrow {
      transform: scale(1.1);
    }
  }
}
