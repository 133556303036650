$gray5: #dddbda;
$gray6: #c9c7c5;
$gray7: #b0adab;
$gray8: #969492;
$gray9: #706e6b;
$gray10: #514f4d;
$gray11: #3e3e3c;
$gray12: #2b2826;
$gray13: #2b2a26;
$gray14: #878787;

$primary: #43B02A;
$primaryActive: #00ABAB;
$light: #f4f6fe;
$lightActive: #e3e5ed;
$dark: #182337;
$accessible: #0070d2;
$accessibleActive: #005fb2;
$contrast: #444444;
$contrastActive: #0d0e12;
$dark-blue: #16325c;

$ink-blue: #062e7a;
$navy-blue: #2556b2;
$aqua-blue: #6196f9;
$light-blue: #b4d7ff;
$bright-blue: #00b9f5;
$gradient-darkblue: #1b4085;
$gradient-lightblue: #4372cb;
$user-profile-blue: #27306a;
$blue-btn: #004487;
$purple: #3140c3;

$dark-teal: #52b9a5;
$teal: #69d5c0;
$occur-yellow: #f5a623;
$dark-red: #fa5353;
$light-red: #e66c67;
$pastel-red: #ff7a7a;
$flush-mahagony: #c23934;
$pastel-blue: #5595df;
$old-blue: #8d8dec;
$vida-loca-green: #52b927;

$dark-grey: #898989;
$road-grey: #909090;
$old-grey: #9b9b9b;
$light-grey: #F7F7F7;
$border-grey: #353533;
$white: #fff;
$black: #000000;
$greyish-white: #eee;
$background-grey: #c3c4c1;
$disabled-grey: #aaa;
$heading-gray: #545454;
$select-disabled: #bebebe;
$modal-footer: #fafafa;
$btn-grey-disabled: #d9dbdd;
$table-border-gray: #dadadb;
$gallery: #efefef;
$error-red: #c23934;
$success-green: #04844b;
$warning-yellow: #ffb75d;
$mark-as-red: #bf0201;

$border-blue: #3140c3;
$border-green: #06c4b6;
$space-grey: #53565A;
$dark-active: #202020;
$blue-ribbon: #0a2c9b;
$border-gray: #dfdfdf;
$light-green: #12e083;
$fun-green: #027e46;
$grey: #505050;
$white: #ffffff;
$gray2: #d7f3f1;
$gray3: #d9ddfe;
$gray4: #f0f6ff;
$gray5: #ececec;
$gray6: #f2f2f2; 
$tangerine-yellow: #ffc700;
$light-periwinkle: #d3deff;
