@import "../../../assets/path.scss";

.heading {
  color: #43b02a;
  font-family: "Open Sans";
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.body-text {
  color: #43b02a;
  text-align: center;
  font-family: "Open Sans";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.modal-body-sectioneone {
  display: flex;
  padding: 1.5rem;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  background: linear-gradient(92deg, #43b02a -5.26%, #00abab 100.92%);
}

.modal-body-sectiontwo {
  display: flex;
  padding: 2.5rem 3.75rem 4.25rem 3.75rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #d9d9d9;
}

.success-icon {
  height: 3.75rem;
  width: 100%;
}

.registration-loader {
  min-height: 100vh;
  position: absolute;
  top: 0;
  opacity: 0.6;
  background: black;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner-border {
    color: #ffffff;
  }
}

.registration-container {
  display: flex;
  min-height: 100vh;

  .left-section {
    background: #353533;
    width: 45%;
    padding: 5.688rem 3.938rem 0 3.938rem;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    position: relative;
    .left-section-heading {
      display: flex;
      gap: 1.125rem;
      align-items: center;

      img {
        width: 5vw;
      }
    }

    .left-heading {
      color: #fff;
      font-family: "Open Sans";
      font-size: 2.875rem;
      font-style: normal;
      font-weight: 300;
      line-height: 100%;
      margin: 1.25rem;
      text-wrap: nowrap;
    }

    .left-section-subheading {
      color: #fff;
      font-family: "Open Sans";
      font-size: 1.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      margin-top: 4.625rem;
      margin-left: 0;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
      hr {
        border: 1px solid #63666a;
      }
    }

    li {
      display: flex;
      justify-content: space-between;
      gap: 64px;
      margin-bottom: 1.938rem;
      margin-top: 1.938rem;
      color: #fff;
      font-family: "Open Sans";
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }

    .system-cover-img {
      width: 25vw;
      height: auto;
      position: absolute;
      right: -4em;
      bottom: 3em;
    }
    .system-img {
      width: 21vw;
      height: auto;
      position: absolute;
      right: -4em;
      bottom: 4em;
    }
  }

  .right-section {
    background: #f2f2f2;
    width: 55%;
    padding: 5.688rem 3.813rem 7.438rem 3.813rem;

    h2 {
      text-align: center;
    }

    form {
      margin-top: 3rem;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      // gap: 30px;
      margin-bottom: 1.875rem;

      input {
        border-radius: 9px;
        border: 1px solid #bbbcbc;
        background: linear-gradient(90deg, #f2f2f2 0.23%, #d0d0ce 109.9%);
        height: 60px;
        padding: 7px 33px 7px 20px;
        width: 100%;
      }

      .input-icon {
        width: 100%;
        position: relative;
        margin-top: 0.75rem;
        img {
          position: absolute;

          /* These are set relative to the height of the input box to bound the box neatly inside. This is aesthetic to me but you may change the dimensions of course. */
          right: 33px;
          top: 1rem;
          width: 2rem;
          height: auto;
          border-radius: 0.3rem;

          /* content in the icon div is centered, without bootstrap or font-awesome you may wish to add your own text in the span */
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
        }
      }
    }
    .error {
      color: red;
      font-family: "Open Sans";
      font-size: 1.25rem;
      margin-bottom: 0;
    }

    .form-group.checkbox-container {
      flex-direction: row;
      gap: 16px;
      align-items: center;

      input {
        width: 36px;
      }
    }

    label {
      color: #000;
      font-family: "Open Sans";
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      /* 20px */
    }

    .button-section {
      display: flex;
      justify-content: center;
      padding-top: 1.938rem;
    }

    .submit-button {
      border-radius: 1.813rem;
      background-image: linear-gradient(
        to bottom right,
        $primary 0%,
        $primaryActive 91%
      );
      display: inline-flex;
      padding: 1rem 7.25rem;
      justify-content: center;
      align-items: center;
      font-size: 1.25rem;
      border: none;
      color: #ffffffd9;
      &:hover {
        color: #ffffff;
      }
    }
  }
}

.amc-modal-container {
  .modal-body {
    padding: 0;
  }
}
