@import "../../../assets/path.scss";

.amc-diffentiator-container {
  padding: 0;

  .up-dots {
    left: 4rem;
    top: 2rem;
  }

  .down-dots {
    right: 9rem;
    bottom: 2rem;
  }

  .title-header {
    padding: 5rem 4rem 5rem 4rem;
    background-color: #353533;
  }

  .amc-diff-icon {
    padding: 1rem 1rem 1rem 2.25rem;
  }

  .amc-diff-title-sub-container {
    background-image: linear-gradient(to bottom right, #000000 0%, #353533 100%);
    color: #ffffffd9;
    border-radius: 2.89rem;
    cursor: pointer;

    &:hover {
      background-image: linear-gradient(
        to bottom right,
        $primary 0%,
        $primaryActive 91%
      );
      color: $white;
    }

    .title {
      padding: 1rem 3.75rem 1rem 0;
      margin-bottom: 0;
      line-height: 2rem;
    }
  }
}
