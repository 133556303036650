@import '../../../assets/path.scss';

.model-container {
  // min-height: calc(var(--vh,1vh) * 100);
  padding: 11rem 4rem 25rem 4rem;

  .pl-7 {
    padding-left: 7rem;
  }

  .pr-7 {
    padding-right: 7.333vw;
  }

  .text-info-container {
    padding: 0 5rem 3rem 2rem;
  }
  .row-reverse {
    flex-direction: row-reverse;
  }
  .menu-info {
    margin: 0 2rem 0.5rem 2rem;
  }
  .text-justify {
    text-align: justify;
  }
  .cards-list {
    // background-image: linear-gradient(to right, #000000 100%, #353533 100%);
    display: flex;
    padding: 2rem 4rem;
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .pl-0 {
    left: 0;
  }

  .settings {
    right: -1rem;
    position: absolute;
    top: 18rem;

    img {
      width: 25rem;
    }
  }
}

@media screen and (min-width: 1280px) {
  .model-vertical-2 {
    top: calc(100% - 1px);
  }
}

.model-vertical {
  border-radius: 100px 0 100px 0;
  border-width: 0 4px 4px 0;
  right: 9rem;
  top: 18%;
  border-style: solid;
  height: 76%;
  width: 60%;
  color: #00abab;
  z-index: -1;
}

.model-vertical-2 {
  border-radius: 100px 0 0 0;
  border-width: 4px 0 0 4px;
  left: 6rem;
  border-style: solid;
  height: 12%;
  width: 60%;
  color: $primaryActive;
  z-index: -1;
  top: calc(94% - 4px);
}

// .model-back-footer {
//   padding-left: 7.5rem;
// }
