.vertical {
  border-radius: 0 0 0 100px;
  border-width: 0 0 4px 4px;
  top: 3%;
  left: 6rem;
  border-style: solid;
  height: 95%;
  width: calc(100% - 6rem);
  color: #43b02a;
  z-index: -1;
}

.left-check-dot {
  left: -39px;
  top: 25px;
}

.right-check-dot {
  right: calc(49rem - 824px);
}
