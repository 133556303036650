/* Sticky footer styles
-------------------------------------------------- */
html {
  min-height: 100%;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  /* Set the fixed height of the footer here */
  line-height: 3.75rem;
  /* Vertically center the text there */
  background-color: #f5f5f5;
}

.login .footer {
  bottom: 120px;
}

.footer a {
  color: #0d6efd;
  text-decoration: underline;
  margin-right: 10px;
  text-align: end;
}


/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

.container-for-links {
  width: auto;
  max-width: 680px;
  padding: 0 15px;
  margin-left: 0px;
}

.text-muted {
  color: #6c757d !important;
}