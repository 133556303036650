@import '../../../assets/path.scss';

.languages-container {
  // min-height: calc(var(--vh,1vh) * 77);
  position: relative;
  padding: 4rem 4rem 2rem 4rem;
  background-image: linear-gradient(to bottom right, #43b02a 0%, #00abab 100%);
  width: 100%;

  .dots {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .text-white {
    color: $white;
  }

  .flex {
    display: flex;
  }

  .languages-sub {
    display: flex;
    width: 100%;
    align-items: center;
    padding-bottom: 2rem;

    .language-user {
      margin: 0 2rem 0.5rem 2rem;
    }
  }

  .languages-sub-card {
    display: flex;
    flex-wrap: wrap;
    padding-left: 7rem;
    padding-bottom: 2rem;

    .language-card {
      border-radius: 100px;
      margin: 10px 10px;
      font-weight: 600;
      width: 10rem;
      background-color: #d7f4d0;
      color: #000000;
      box-sizing: border-box;
      text-align: center;
      line-height: 50px;
      padding: 0 35px;
      vertical-align: middle;
    }
  }

  // .languages-footer {
  //   padding-left: 7.5rem;
  // }
}
