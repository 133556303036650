.amc-modal {
  box-sizing: border-box;
  .modal-content {
    border-radius: 2.288rem;
  }

  .amc-modal-body {
    background-color: #f2f2f2;
    border-radius: 2.188rem;
    position: relative;

    .modal-close-btn {
      position: absolute;
      right: 2rem;
      z-index: 99;
    }
  }

  .pl-icon {
    padding-left: 0.875rem;
  }
  
  .amc-modal-desc {
    padding-right: 21.3125rem;
    min-height: 28.625vh;
  }

  .amc-modal-title {
    font-weight: lighter;
    font-size: 2.875rem;
  }

  .amc-modal-sub-title {
    color: #43b02a;
    font-weight: 600;
    margin-top: 2.4375rem;
    margin-bottom: 2.375rem;
  }
}

.amc-model-container {
  background-image: linear-gradient(to bottom right, #000000 0%, #353533 100%);

  .amc-modal-content {
    display: flex;
    flex-direction: row;
    min-height: 87vh;

    .amc-modal-left-panel {
      width: 35vw;
      display: flex;
      align-content: center;
      padding: 2rem 0;
      flex-flow: column wrap;
      gap: 2rem;
      .model-item {
        background-image: linear-gradient(
          to bottom right,
          #000000 0%,
          #353533 100%
        );
        padding: 1rem 2rem;
        border-radius: 3.125rem;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &.status-active {
          background-image: linear-gradient(
            to bottom right,
            #43b02a 0%,
            #00abab 100%
          );
        }

        &.item-inactive {
          background-image: linear-gradient(
            to bottom right,
            #d7f4d0 0%,
            #9dd4cf 100%
          );
          color: #000000;
          font-weight: 600;
        }
      }
    }

    .amc-modal-right-panel {
      width: 65vw;
      padding: 2rem 6rem;
      color: #ffffff;
    }
  }
}

.image-container {
  width: 100%;
  height: 55vh;
  background-size: contain;
  background-repeat: no-repeat;
}

.amc-modal-item {
  padding: 2.6125rem 7.1875rem;
  min-height: 87vh;
}

@keyframes shimmer {
  to {
    background-position-x: 0%;
  }
}
