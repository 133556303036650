@import "../../../assets/path.scss";

.h-40 {
  height: calc(100vh - 156px);
}

.banner-container {
  width: 100%;
  background-color: #353533;
  background-size: cover;
  background-repeat: no-repeat;
  padding-left: 5vw;
}

.download {
  border: none;
  box-sizing: border-box;
  font-weight: 600;
  text-wrap: nowrap;
  letter-spacing: 0.75px;
  font-size: 1rem;

  &:hover {
    .icon {
      fill: $white;
    }
  }
}

.amc-drop-down {
  background-image: linear-gradient(to bottom right, #353533 0%, #000000 100%);
  border-radius: 6.25rem;
  border: 1px solid #54535a;
  color: #ffffffd9;
  &:hover {
    color: $white;
    background-image: linear-gradient(
      to bottom right,
      $primary 0%,
      $primaryActive 91%
    );
  }
}

.amc-drop-down-liner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}

.link-plugin {
  color: #ffffffd9;
  font-size: 1.25rem;
  z-index: 3;
  text-wrap: nowrap;
  &:hover {
    color: $white;
  }
  &:active {
    color: $white !important;
  }
}

.system-banner {
  width: 100%;
  z-index: 2;
}

.download-dropdown {
  .dropdown-toggle::after {
    display: none;
  }

  .btn {
    border-radius: 6.25rem;
    color: #ffffffd9;
    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      background-image: linear-gradient(
        to bottom right,
        $primary 0%,
        $primaryActive 91%
      );
      color: $white;
    }
  }

  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active,
  .btn:first-child:active,
  .btn.active,
  .btn.show {
    background-image: linear-gradient(
      to bottom right,
      $primary 0%,
      $primaryActive 91%
    );
    color: $white;
  }

  .download-dropdown-menu {
    background-color: #353533;
    box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    width: 100%;

    .download-dropdown-item {
      color: $white;

      &:hover {
        background-color: #353533;
        color: #43b02a;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .banner-container {
    background-image: url("../../../../public/images/banner-one.svg");
  }
}

@media screen and (min-width: 1280px) {
  .banner-container {
    background-image: url("../../../../public/images/jpgs/title_banner_1280.jpg");
  }
}

@media screen and (min-width: 1366px) {
  .banner-container {
    background-image: url("../../../../public/images/jpgs/title_banner_1366.jpg");
  }
}

@media screen and (min-width: 1600px) {
  .banner-container {
    background-image: url("../../../../public/images/jpgs/title_banner_1600.jpg");
  }
}

@media screen and (min-width: 1920px) {
  .banner-container {
    background-image: url("../../../../public/images/jpgs/title_banner_1920.jpg");
  }
}

@media (max-height: 687px) {
  .banner-container {
    height: calc(100vh - 60%);
  }
  .amc-drop-down-liner {
    position: absolute;
    left: 5vw;
    top: calc(100vh - 58%);
  }
  .img-banner {
    position: absolute;
    bottom: 5vh;

    .mb-1 {
      margin-bottom: 1rem;
    }

    .logo-text {
      font-size: 2.625rem;
      font-weight: 300;
      color: $white;
      text-wrap: nowrap;
    }
  }
  .system-banner {
    .system-bg-dots {
      right: 3vw;
      top: 6vh;
      width: 35vw;
    }
    .img-system {
      right: 6.125vw;
      top: 22vh;
      width: 21vw;
    }
  }

  .download {
    width: 18.75vw;
    padding: 10px 60px;
    .icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 1rem;
    }
  }
}

@media (min-height: 689px) {
  .banner-container {
    height: 75%;
  }
  .amc-drop-down-liner {
    position: absolute;
    left: 5vw;
    top: 78%;
  }

  .img-banner {
    position: absolute;
    bottom: 5vh;
    .mb-1 {
      margin-bottom: 1rem;
    }

    .logo-text {
      font-size: 3.625rem;
      font-weight: 300;
      color: $white;
      text-wrap: nowrap;
    }
  }

  .system-banner {
    .system-bg-dots {
      right: 3vw;
      top: 8vh;
      width: 35vw;
    }

    .img-system {
      right: 6.125vw;
      top: 11vw;
      width: 21vw;
    }
  }

  .download {
    width: 19.75rem;
    padding: 10px 60px;
    .icon {
      width: 2rem;
      height: 2rem;
      margin-left: 1rem;
    }
  }
}

@media (min-height: 720px) {
  .banner-container {
    height: calc(100vh - 34%);
  }

  .img-banner {
    position: absolute;
    bottom: 15vh;

    .mb-1 {
      margin-bottom: 1rem;
      width: 8vw;
    }

    .logo-text {
      font-size: 3.625rem;
      font-weight: 300;
      color: $white;
      text-wrap: nowrap;
    }
  }

  .system-banner {
    .system-bg-dots {
      right: 3vw;
      top: 0;
      width: 50vw;
    }

    .img-system {
      right: 10vw;
      top: 20vh;
      width: 28vw;
    }
  }
  .amc-drop-down-liner {
    position: absolute;
    left: 5vw;
    top: calc(100vh - 48%);
  }

  .download {
    width: 25vw;
    padding: 0.675rem 2.75rem;

    .icon {
      width: 2rem;
      height: 2rem;
      margin-left: 1rem;
    }
  }
}

@media (min-height: 940px) {
  .banner-container {
    height: calc(100vh - 50%);
  }

  .img-banner {
    position: absolute;
    bottom: 3vh;

    .mb-1 {
      margin-bottom: 1rem;
      width: 8vw;
    }

    .logo-text {
      font-size: 3.625rem;
      font-weight: 300;
      color: $white;
      text-wrap: nowrap;
    }
  }

  .system-banner {
    .system-bg-dots {
      right: 3vw;
      top: 0;
      width: 50vw;
    }

    .img-system {
      right: 10vw;
      top: 23vh;
      width: 26vw;
    }
  }
  .amc-drop-down-liner {
    position: absolute;
    left: 5vw;
    top: calc(100vh - 48%);
  }

  .download {
    width: 19vw;
    padding: 0.675rem 2.75rem;

    .icon {
      width: 2rem;
      height: 2rem;
      margin-left: 1rem;
    }
  }
}

@media (min-height: 1080px) {
  .banner-container {
    height: calc(100vh - 34%);
  }

  .img-banner {
    position: absolute;
    bottom: 15vh;

    .mb-1 {
      margin-bottom: 1rem;
      width: 10vw;
    }

    .logo-text {
      font-size: 4.625rem;
      font-weight: 300;
      color: $white;
      text-wrap: nowrap;
    }
  }

  .system-banner {
    .system-bg-dots {
      right: 3vw;
      top: 4vh;
      width: 48vw;
    }

    .img-system {
      right: 10vw;
      top: 26vh;
      width: 26vw;
    }
  }
  .amc-drop-down-liner {
    position: absolute;
    left: 5vw;
    top: calc(100vh - 44%);
  }

  .download {
    width: 22vw;
    padding: 1rem 3.75rem;

    .icon {
      width: 2rem;
      height: 2rem;
      margin-left: 1rem;
    }
  }
}
