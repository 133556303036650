@import "../../../assets/path.scss";

.tutorials-container {
  position: relative;
  padding: 4rem 0 2rem 1rem;
  background-image: url("../../../../public/images/jpgs/code_demo_frame_1280.jpg");
  background-size: cover;
  width: 100%;
  .dots {
    position: absolute;
    right: 0;
    top: 1rem;
  }

  .bottom-system {
    width: 22vw;
    height: auto;
  }

  .tutorial-sub {
    display: flex;
    width: 100%;
    align-items: center;
    padding-bottom: 2rem;

    .tutoral-icon {
      margin: 0 2rem 0.5rem 2rem;
    }
  }

  .amc-tutorials-footer {
    display: flex;
    justify-content: space-between;
    padding-left: 4rem;
    padding-bottom: 1rem;

    .back-btn {
      display: flex;
      align-items: end;
    }
  }

  .video-container {
    padding: 0 5rem 0 4rem;
    .amc-video {
      width: 100%;
      border: 1px solid #86dd72;
      border-radius: 30px;
      height: 85vh;
    }
  }
}
