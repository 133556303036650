@import '../../../assets/path.scss';

.slide-show-container {
  min-height: calc(var(--vh, 1vh) * 90);
  padding: 0 4rem 4rem 4rem;
  .divider-one {
    height: 4.25rem;
  }

  .slide-show {
    width: 100%;
    margin-top: 2rem;
    box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 2.125rem;
    overflow: hidden;
    padding: 1rem;
    height: 75vh;
    object-fit: contain;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
