@import '../../../assets/path.scss';

.about-container {
  // min-height: calc(var(--vh, 1vh) * 100);
  padding: 2rem 4rem;
  overflow: hidden;

  .pl-7 {
    padding-left: 7rem;
  }

  .icon-info {
    margin-right: 2rem;
    margin-bottom: 0.5rem;
  }

  .img-system {
    right: 0;
    top: 30%;
  }
}

.dots {
  right: 0;
  max-width: 100%;
}

.content {
  margin: 2rem 8rem 1rem 0rem;
  font-size: 1rem;
}

.center {
  justify-content: center;
}

.contact-info {
  p {
    margin: 0;
  }
}

.mt-8 {
  margin-top: 2.5rem;
}

.about-info-cont {
  padding: 2rem 2rem 4rem 2rem;
  width: 30rem;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to bottom right, #43B02A 0%, #00ABAB 100%);
  border-radius: 1.9375rem;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 50%);

}

.email-icon {
  line-height: 3rem;
  margin-right: 1rem;
}

.f-500 {
  font-weight: 500;
}

.tag {
  border-radius: 1.8125rem;
  background-image: linear-gradient(to bottom right, #43B02A 0%, #00ABAB 100%);
  width: 10.875rem;
  margin-right: 2.5rem;
  font-weight: 500;
  box-sizing: border-box;
  text-align: center;
  line-height: 50px;
  padding-left: 0.5rem;
  vertical-align: middle;
  color: #ffffffd9;
  &:hover {
    color: #ffffff;
  }
  img {
    width: 3.125rem;
  }
}

.pb-4 {
  padding-bottom: 4rem;
}

.pt-6 {
  padding-top: 3.875rem;
}
// .about-back-footer {
//   padding-left: 7rem;
//   left: 0;
//   bottom: -8rem;
// }
