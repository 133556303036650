.search-container {
  width: 26vw;
  #search {
    color: black;
    margin: 0.5rem;
    float: right;
    padding: 0.5rem;
    border: 2px solid grey;
    border-radius: 10px;
    width: 100%;
  }

  #search::after {
    clear: both;
  }
}

.noResults {
  text-align: center;
  font-style: italic;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
}
