@import '../../../assets/path.scss';

.feature-container {
  // min-height: calc(var(--vh,1vh) * 100);
  padding: 2rem 3rem 0 3rem;

  .pl-7 {
    padding-left: 7.333vw;
  }
  .menu-info {
    margin: 0 1.667vw 0.667vw 1.667vw;
  }
  .feature-list {
    // background-image: linear-gradient(to right, #000000 100%, #353533 100%);
    display: flex;
    padding: 2.667vw;
    flex-wrap: wrap;
  }

  .dots {
    right: 0;
  }

  .settings {
    right: 0rem;
    position: absolute;
    top: 18rem;

    img {
      width: 25.833vw;
    }
  }
}

.display-inline-flex {
  display: flex;
  flex-direction: column-reverse;
}

.feature-vertical {
  border-radius: 0 0 0 100px;
  border-width: 0 0 4px 4px;
  top: 0;
  left: 6rem;
  border-style: solid;
  height: 100%;
  width: 60%;
  color: #43b02a;
  z-index: -1;
}

.feature-vertical-2 {
  border-radius: 0 100px 0 0;
  border-width: 4px 4px 0 0;
  right: 9rem;
  border-style: solid;
  top: calc(100% - 3px);
  height: 18%;
  width: 30%;
  color: #43b02a;
  z-index: -1;
}

@media screen and (min-width: 1280px) {
  .feature-vertical-2 {
    top: calc(100% - 4px);
  }
}

@media screen and (min-width: 1366px) {
  .feature-vertical-2 {
    top: calc(100% - 4px);
  }
}

@media screen and (min-width: 1600px) {
  .feature-vertical-2 {
    top: calc(100% - 3px);
  }
}

@media screen and (min-width: 1920px) {
  .feature-vertical-2 {
    top: calc(100% - 4px);
  }
}

.back-footer {
  padding-left: 7.5rem;
  margin-bottom: 5rem;
}
