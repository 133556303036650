@import "../../../../assets/path.scss";

.p-all {
  padding: 0 1rem 1rem 1rem;
}

.card-container-active {
  background-image: linear-gradient(to bottom right, $black 0%, $border-grey 100%);
  width: 20.216vw;
  border-radius: 1.563rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid $space-grey;
  margin: 6px;

  &:hover {
    background-image: linear-gradient(to bottom right, #43b02a 0%, #00abab 100%);
    .btn-card {
      img {
        padding-left: 1rem;
      }
    }
  }

  .title {
    color: $white;
    font-weight: 600;
  }

  .title-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .card-text {
    color: $white;
    font-size: 1rem;
  }

  .btn-card {
    color: $white;
    border-radius: 1.563rem;
    border-color: $gray6;
    padding: 0 0.79rem;
    font-size: 1rem;
    transition: width 2s;
    position: absolute;
    bottom: 5.083vh;
    &:hover {
      background: none;
    }
    &:active {
      color: #ffffff;
    }
    img {
      transition: padding-left 0.5s;
    }
  }
}


.card-container-inactive {
  background-color: #353533;
  width: 20.216vw;
  border-radius: 1.563rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 1px dashed #A7A8AA;
  margin: 6px;

  &:hover {
    background-image: linear-gradient(to bottom right, #D7F4D0 0%, #9DD4CF 100%);
    border: 1px dashed #FFFFFF;

    color: #000000;
    .title, .card-text {
      color: $black;
    }
    .btn-card {
      color: $black;
      border-color: $black;
      img {
        padding-left: 1rem;

      }
    }
  }

  .title {
    color: $white;
    font-weight: 600;
  }

  .title-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .card-text {
    color: $white;
    font-size: 1rem;
  }

  .btn-card {
    color: #F2F2F2;
    border-radius: 1.563rem;
    border-color: $gray6;
    padding: 0 0.79rem;
    font-size: 1rem;
    transition: width 2s;
    position: absolute;
    bottom: 5.083vh;
    &:hover {
      background: none;
    }
    &:active {
      color: #000000;
    }
    img {
      transition: padding-left 0.5s;
    }
  }
}

.feature-card-body {
  padding: 2.083vw 1vw;
  position: relative;
  height: 60vh;
}

.model-card-body {
  padding: 2.083vw 1vw;
  position: relative;
  height: 30vh;
  display: flex;
  justify-content: center;
}
