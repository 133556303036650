.flex-center-container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;

  .page-not-found {
    width: fit-content;
    color: #ffffff;
    font-size: xx-large;
    text-wrap: nowrap;
    border: 2px solid #ececec;
    background: linear-gradient(92deg, #43b02a -5.26%, #00abab 100.92%);
    padding: 3.5% 26.5%;
    border-radius: 25px;
    -webkit-box-shadow: 10px 10px 11px 0px rgba(158, 158, 158, 1);
    -moz-box-shadow: 10px 10px 11px 0px rgba(158, 158, 158, 1);
    box-shadow: 10px 10px 11px 0px rgba(158, 158, 158, 1);
  }
}
