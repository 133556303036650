@import '../../../assets/path.scss';

.adv-container {
  // min-height: calc(var(--vh, 1vh) * 100);
  padding: 0;

  .title-header {
    padding: 2rem 4rem 2rem 4rem;
    background-color: #353533;
  }

  .loader-container {
    box-sizing: border-box;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 9vw;
    justify-content: center;
  }

  .table-head {
    // background: linear-gradient(to right, #43b02a 0%, #00abab 100%) 1;
    border-width: 0 0 5px 0;
    border-style: solid;
    border-color: #43b02a !important;
    th {
      background-color: #000000 !important;
      color: #43b02a !important;
      border: none;
      padding: 1.5rem;
      padding-left: 3.375rem;
      font-size: 1.125rem;
      letter-spacing: 0.5px;
      text-wrap: nowrap;
    }
  }

  .table-body {
    td {
      padding-top: 2.188rem;
      padding-bottom: 2.188rem;
      border: none;
      box-shadow: 8px 0 7.5px 0 rgba(0, 0, 0, 0.3);
    }

    td:first-child {
      color: #000000;
      padding-left: 3.375rem;
      text-wrap: nowrap;
    }
    tr:last-child {
      td {
        color: #000000;
        padding-left: 3.375rem;
        background-color: #d7f4d0;
      }
    }
  }

  .mlr-3 {
    margin: 0 3rem 0 0;
  }
}
